import clx from "classnames";
import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useStore } from "../../store";
import { OTPSkeleton } from "../Skeleton";
import styles from "./index.module.scss";

const MAX_LENGTH = 6;

const useMobilePlatform = () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);
  return { isIOS, isAndroid };
};

export const OTPField = ({
  onChangeHandler,
  hasError = false,
  isDisabled = false,
}) => {
  const loading = useStore((state) => state.loading);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [value, setValue] = useState("");
  const [focusedIndex, setFocusedIndex] = useState(0);
  const { isIOS } = useMobilePlatform();

  // Create array of digits for display
  const valueArray = useMemo(() => {
    return Array(MAX_LENGTH)
      .fill("")
      .map((_, index) => value[index] || "");
  }, [value]);

  useEffect(() => {
    if (hasError) {
      setValue("");
      setFocusedIndex(0);
      inputRef.current?.focus();
    }
  }, [hasError]);

  useEffect(() => {
    if (!loading && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
        setFocusedIndex(0);
        console.log("[OTPField] Scrolling container into view");
      }, 100);
    }
  }, [loading]);

  // Handle visual viewport changes (mobile keyboard)
  useEffect(() => {
    const handleVisualViewport = () => {
      console.log("[OTPField] Visual viewport changed");
      if (window.visualViewport) {
        // Add a small delay to ensure keyboard is fully shown
        setTimeout(() => {
          containerRef.current?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }, 100);
      }
    };

    window.visualViewport?.addEventListener("resize", handleVisualViewport);
    window.visualViewport?.addEventListener("scroll", handleVisualViewport);

    return () => {
      window.visualViewport?.removeEventListener(
        "resize",
        handleVisualViewport
      );
      window.visualViewport?.removeEventListener(
        "scroll",
        handleVisualViewport
      );
    };
  }, []);

  const onChange = useCallback(
    (e) => {
      console.log("[OTPField] onChange", {
        newValue: e.target.value,
        focusedIndex,
        currentValue: value,
      });

      const inputChar = e.target.value.replace(/\D/g, "").slice(-1);

      if (inputChar) {
        let newValue = value;

        // Replace digit at focused index or append
        if (focusedIndex < MAX_LENGTH) {
          newValue =
            value.slice(0, focusedIndex) +
            inputChar +
            value.slice(focusedIndex + 1);
          setFocusedIndex(Math.min(focusedIndex + 1, MAX_LENGTH - 1));
        }

        console.log("[OTPField] Setting new value", { newValue });
        setValue(newValue);
        onChangeHandler(newValue);
      }
    },
    [onChangeHandler, focusedIndex, value]
  );

  const onKeyDown = useCallback(
    (e) => {
      console.log("[OTPField] onKeyDown", {
        key: e.key,
        focusedIndex,
        currentValue: value,
      });

      switch (e.key) {
        case "Backspace":
          e.preventDefault();
          if (value.length > 0) {
            // If we're not at the start, delete the character before the focused position
            const newValue =
              value.slice(0, focusedIndex - 1) + value.slice(focusedIndex);
            setValue(newValue);
            onChangeHandler(newValue);
            setFocusedIndex(Math.max(focusedIndex - 1, 0));
          }
          break;

        case "ArrowLeft":
          e.preventDefault();
          setFocusedIndex(Math.max(focusedIndex - 1, 0));
          break;

        case "ArrowRight":
          e.preventDefault();
          setFocusedIndex(Math.min(focusedIndex + 1, value.length));
          break;

        case " ":
        case "Spacebar":
        case "Space":
          e.preventDefault();
          break;

        default:
          // Allow normal typing behavior for numbers
          break;
      }
    },
    [value, onChangeHandler, focusedIndex]
  );

  const onPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pasteData = e.clipboardData.getData("text");
      const newValue = pasteData.replace(/\D/g, "").slice(0, MAX_LENGTH);
      setValue(newValue);
      setFocusedIndex(Math.min(newValue.length, MAX_LENGTH - 1));
      onChangeHandler(newValue);
    },
    [onChangeHandler]
  );

  const focusInput = useCallback((index) => {
    console.log("[OTPField] Focusing input at index", index);
    inputRef.current?.focus();
    setFocusedIndex(index);
  }, []);

  if (loading) {
    return (
      <div className={styles.otp_field_container}>
        <OTPSkeleton wrapperCss={styles.otp_skelly} />
      </div>
    );
  }

  return (
    <div ref={containerRef} className={styles.otp_wrapper}>
      {hasError && <div className={styles.otp_err}>{hasError}</div>}
      <div className={styles.otp_field_container}>
        {/* Hidden input for actual value */}
        <input
          ref={inputRef}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="off"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          maxLength={MAX_LENGTH}
          disabled={isDisabled}
          className={styles.otp_hidden_input}
          aria-label="OTP input"
        />

        {/* Visual display boxes */}
        <div className={styles.otp_boxes}>
          {valueArray.map((digit, index) => (
            <div
              key={index}
              className={clx(
                styles.otp_box,
                index === focusedIndex && styles.active,
                hasError && styles.error,
                digit && styles.filled
              )}
              onClick={() => {
                focusInput(index);

                if (isIOS) {
                  console.log(
                    "[OTPField] Input clicked OTP Box, scrolling into view"
                  );
                  inputRef.current.style.opacity = 0.1;
                  inputRef.current.focus();
                  setTimeout(() => {
                    inputRef.current.style.opacity = 0;
                  }, 500);
                }
              }}
            >
              {digit || <span className={styles.otp_placeholder}>0</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
